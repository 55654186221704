import React from 'react';
import { Modal, ModalHeader, ModalBody } from '@msdyn365-commerce-modules/utilities';
import { IAddToCartComponentProps } from '../themes/ahi/views/components/addtocart.component';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { IConfigurableItemEntity } from '../actions/DataServiceEntities.g';
import { getConfigurableItemsControllerAsync } from '../actions/DataActionExtension.g';
import { ICartActionResult } from '@msdyn365-commerce/global-state';
import { SimpleProduct, ProductDimensionType } from '@msdyn365-commerce/retail-proxy';
import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { getByIdsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getExtendedCartState } from '../actions/get-extended-cart-state';
import { IExtendedCartState } from '../actions/cart-state-data-action';
import { v4 as uuid } from 'uuid';

export interface AddConfigurableItemToCartInput {
    subItemProducts: { product: SimpleProduct; isPriceKeyedIn?: boolean; customPrice?: number }[];
    mainProduct: { product: SimpleProduct; isPriceKeyedIn?: boolean; customPrice?: number; currency?: string };
    CartLineUUID: string;
    count: number;
    catalogId?: number;
}

export interface AddtoCartInput {
    product: SimpleProduct;
    count: number;
    catalogId?: number;
}

interface ModalProps {
    isOpen: boolean;
    setCloseModal: () => void;
    props: IAddToCartComponentProps;
    quantity: number;
    handleAddConfigurableItemsSuccess: (
        props: IAddToCartComponentProps,
        setDisabled: (disabled: boolean) => void,
        setItemsAddedToCartDialogOpen: (opened: boolean) => void,
        setErrorMessage: (message: string) => void,
        cartState: IExtendedCartState,
        addToCartInput: {
            product: SimpleProduct;
            count: number;
        }[],
        addToCartResult: ICartActionResult
    ) => Promise<void>;
    setDisabled: (disabled: boolean) => void;
    setItemsAddedToCartDialogOpen: (opened: boolean) => void;
    setErrorMessage: (message: string) => void;
}

interface ConfigurableItems extends IConfigurableItemEntity {
    isSelected: boolean;
}

export interface IBusinessSignUpErrorProps {
    id?: string;
    className: string;
    type?: string;
    message?: string;
}
export default function subitemModal({
    isOpen,
    setCloseModal,
    props,
    handleAddConfigurableItemsSuccess,
    setDisabled,
    setErrorMessage: setAddtoCartErrorMenssage,
    setItemsAddedToCartDialogOpen,
    quantity
}: ModalProps) {
    const [isReview, setIsReview] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [configurableItems, setConfigurableItems] = React.useState<IConfigurableItemEntity[]>([]);

    const [selectedSubItems, setSelectedSubItems] = React.useState<ConfigurableItems[]>([]);
    const [isError, setIsError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState('');

    const handleSubItemCheckBoxToggle = (item: ConfigurableItems) => {
        const selectedNewItem = selectedSubItems.map(selectedItem => {
            if (selectedItem.SUBPRODUCTLINERECID === item.SUBPRODUCTLINERECID) {
                return { ...selectedItem, isSelected: !item.isSelected };
            }
            return selectedItem;
        });
        setSelectedSubItems([...selectedNewItem]);
    };

    const mainProduct = props.data?.product;
    const mainProductCurrency = props.data?.price?.CurrencyCode ?? '';

    const findSubTotal = (items: ConfigurableItems[], quantity: number) => {
        return (
            items.reduce((acc, item) => {
                return acc + (item.isSelected ? (item.AHIINCLUDEINBASEPRICE ? 0 : item?.AMOUNT ?? 0) : 0);
            }, mainProduct?.AdjustedPrice ?? 0) * quantity
        );
    };

    const getRetailKitConfigurableItems = async (props: IAddToCartComponentProps, masterProductId: string) => {
        const selectedProductDimensions = props.data?.product.Dimensions;
        try {
            const response = await getConfigurableItemsControllerAsync(
                { callerContext: props.context.actionContext },
                masterProductId,
                selectedProductDimensions?.find(dimension => dimension.DimensionTypeValue === ProductDimensionType.Size)?.DimensionValue
                    ?.Value ?? '',
                selectedProductDimensions?.find(dimension => dimension.DimensionTypeValue === ProductDimensionType.Color)?.DimensionValue
                    ?.Value ?? '',
                selectedProductDimensions?.find(dimension => dimension.DimensionTypeValue === ProductDimensionType.Style)?.DimensionValue
                    ?.Value ?? '',
                selectedProductDimensions?.find(dimension => dimension.DimensionTypeValue === ProductDimensionType.Configuration)
                    ?.DimensionValue?.Value ?? ''
            );
            return response;
        } catch (err) {
            console.log('error:', err);
            return [];
        }
    };

    const fetchConfigurableItems = async () => {
        const configurableItems = await getRetailKitConfigurableItems(props, props.data?.product.ItemId ?? '');
        setConfigurableItems([...configurableItems]);
        const subItemsList = configurableItems.map(item => ({ isSelected: false, ...item }));
        setSelectedSubItems([...subItemsList]);
    };

    React.useEffect(() => {
        isOpen && fetchConfigurableItems();
    }, [isOpen]);

    React.useEffect(() => {
        const subtotal = findSubTotal(selectedSubItems, quantity);
        setTotal(subtotal);
    }, [selectedSubItems]);

    const handleSubmitClick = () => {
        setIsReview(true);
    };

    const handleClose = () => {
        setIsReview(false);
        setIsError(false);
        setErrorMessage('');
        setCloseModal();
    };

    const handleGoBack = () => {
        setIsReview(false);
        setIsError(false);
        setErrorMessage('');
    };

    const handleAddConfigurableItemsToCartClick = async () => {
        if (!mainProduct) {
            return;
        }
        setIsError(false);
        setIsLoading(true);
        const configurableItemUUID = uuid();
        const configurableItemToAdd: AddConfigurableItemToCartInput = await getAddSubItemsToCartInput({
            selectedSubItems,
            mainProduct,
            configurableItemUUID,
            count: quantity,
            currency: props.data?.price?.CurrencyCode,
            catalogId: props.catalogId,
            addtoCartComponentProps: props
        });
        const itemsToAdd = await getAddToCartInputItems({
            selectedSubItems,
            mainProduct,
            configurableItemUUID,
            count: quantity,
            catalogId: props.catalogId,
            addtoCartComponentProps: props
        });
        try {
            const ExtendedCartState = await getExtendedCartState(props.context.actionContext);
            const currentCart = ExtendedCartState.cart;
            if (!currentCart) {
                setIsError(true);
                setIsLoading(false);
                setErrorMessage('Cant create or find a cart');
                return;
            }
            const addConfigurableProductToCartResult = await ExtendedCartState.addConfigurableProductToCart(configurableItemToAdd);
            if (addConfigurableProductToCartResult.status === 'SUCCESS') {
                setIsLoading(false);
                handleClose();

                handleAddConfigurableItemsSuccess(
                    props,
                    setDisabled,
                    setItemsAddedToCartDialogOpen,
                    setAddtoCartErrorMenssage,
                    ExtendedCartState,
                    itemsToAdd,
                    addConfigurableProductToCartResult
                );
            }
            if (addConfigurableProductToCartResult.status === 'FAILED') {
                setIsLoading(false);
                setIsError(true);
                setErrorMessage(
                    (addConfigurableProductToCartResult.errorDetails?.LocalizedMessage === 'A validation error occurred.'
                        ? addConfigurableProductToCartResult.validationResults?.[0].LocalizedMessage
                        : addConfigurableProductToCartResult.errorDetails?.LocalizedMessage) ||
                        'Selected items could not be added to cart. Please try again.'
                );
            }
        } catch (error) {
            setIsError(true);
            setErrorMessage('Items could not be added to cart. Please try again.');
            setIsLoading(false);
            console.log(error);
        }
    };
    const includeInbasePrice = configurableItems.every(item => item.AHIINCLUDEINBASEPRICE === 1);
    return (
        <Modal isOpen={isOpen} className='msc__subitem-modal-container' wrapClassName='sub-item-modal'>
            <ModalHeader className='sub-item-modal-header'>
                <span>Configure Options/Features</span>
                <div className='sub-item-modal-close-icon' onClick={handleClose}></div>
            </ModalHeader>
            <ModalBody className='sub-item-modal-body'>
                <MainProductDetails
                    product={mainProduct}
                    isSubmitted={isReview}
                    quantity={quantity}
                    currency={mainProductCurrency}
                    isIncluded={includeInbasePrice}
                    total={total}
                />
                {!isReview && <p className='sub-item-select-info'>Please configure your product for best combination</p>}
                <SubItemList
                    isReview={isReview}
                    selectedSubItems={selectedSubItems}
                    configurableItems={configurableItems}
                    onSelectSubItem={(item: ConfigurableItems) => handleSubItemCheckBoxToggle(item)}
                />
                <TotalDetails
                    total={total}
                    isSubmitted={isReview}
                    currency={mainProductCurrency}
                    // isIncluded={includeInbasePrice}
                    // type={productType}
                />
                {isError && <ConfigurableItemError key='error' id='error' className='sub-item-error' message={errorMessage} />}
            </ModalBody>
            <ModalFooter className='sub-item-modal-footer'>
                <ActionButton
                    onClose={handleClose}
                    onBack={handleGoBack}
                    onSubmit={handleSubmitClick}
                    isSubmitted={isReview}
                    isLoading={isLoading}
                    onAddToCart={handleAddConfigurableItemsToCartClick}
                />
            </ModalFooter>
        </Modal>
    );
}

const CheckBox = React.memo(
    ({
        checked,
        name,
        onChange,
        show,
        disable
    }: {
        checked?: boolean;
        name?: string;
        onChange: () => void;
        show: boolean;
        disable?: boolean;
    }) => {
        if (!show) {
            return <></>;
        }
        return (
            <label className='check-container'>
                <input type='checkbox' checked={checked || false} className='m-0' onClick={onChange} />
                <span className={`checkmark ${disable ? 'disable' : ''}`}></span>
            </label>
        );
    }
);

function MainProductDetails({
    isSubmitted,
    product,
    quantity,
    currency,
    total,
    isIncluded
}: {
    isSubmitted: boolean;
    product?: SimpleProduct;
    quantity: number;
    currency: string;
    total: number;
    isIncluded: boolean;
}) {
    if (!product) {
        return <></>;
    }
    const extraClass = isSubmitted ? 'padding-left' : '';
    const mainSectionClass = `main-item-section row ${isSubmitted ? 'divider' : ''}`;
    const mainItemPrice = isSubmitted && isIncluded ? (total === 0 ? product.BasePrice : total) : product.AdjustedPrice;
    const mainItemName = product.ProductNumber;
    const mainItemNameSegments = mainItemName?.split(' :');
    const mainItemNameFormatted = mainItemNameSegments?.filter(segment => segment.trim() !== '').join(' : ');
    return (
        <div className={mainSectionClass}>
            <div className='col-3 d-flex align-items-center'>
                <CheckBox onChange={() => {}} show={!isSubmitted} checked={true} disable />
                <span className={`${!isSubmitted && 'px-2'}`}>
                    <span className='main-item-text'>{mainItemNameFormatted}</span> <br /> Quantity {quantity}
                </span>
            </div>
            <div className={`col-6  ${extraClass} main-item-text main-product-name`}>
                <span>{product.Name}</span>
            </div>
            {/* <div className={`col-2  ${extraClass} text-right`}>
                <span className='fw-bold'>{mainItemPrice}</span>
                <span>{product.BasePrice}</span>
            </div> */}
            <div className={`col-3  ${extraClass} text-right`}>
                <span className='main-item-text mr-3'>{mainItemPrice}</span>
                <span className='main-item-text'>{currency}</span>
            </div>
        </div>
    );
}

function SubItemList({
    isReview,
    selectedSubItems,
    configurableItems,
    onSelectSubItem
}: {
    isReview: boolean;
    selectedSubItems: ConfigurableItems[];
    configurableItems: IConfigurableItemEntity[];
    onSelectSubItem?: (item: ConfigurableItems) => void;
}) {
    const priceAndCurrencyHeaderShow = isReview && configurableItems.every(item => item.AHIINCLUDEINBASEPRICE === 1);
    const noItemsSelected = isReview && !Boolean(selectedSubItems.some(item => item.isSelected));
    if (noItemsSelected) {
        return <></>;
    }
    return (
        <div className='sub-item-section '>
            <div className='d-none d-md-block'>
                <div className='row mt-2 sub-item-header'>
                    <span className={`col-3 px-0  ${!isReview && 'pl-4'}`}>Option Code</span>
                    <span className='col-5 px-0'>Description</span>
                    {!priceAndCurrencyHeaderShow && (
                        <>
                            <span className='col-2 px-0 text-right'>List Price</span>
                            <span className='col-2 px-0 text-right'>Currency</span>
                        </>
                    )}
                </div>
            </div>
            {selectedSubItems.map(item => {
                return (
                    <SubItem
                        key={item.RECID}
                        subItem={item}
                        includeInbasePrice={item.AHIINCLUDEINBASEPRICE === 1}
                        isReview={isReview}
                        isSelected={item.isSelected}
                        onCheckBoxChange={() => {
                            onSelectSubItem?.(item);
                        }}
                    />
                );
            })}
        </div>
    );
}

function SubItem({
    subItem,
    includeInbasePrice,
    isSelected,
    isReview,
    onCheckBoxChange
}: {
    subItem: ConfigurableItems;
    includeInbasePrice?: boolean;
    isSelected: boolean;
    isReview: boolean;
    onCheckBoxChange?: () => void;
}) {
    const show = isReview ? subItem.isSelected : true;
    const divClass = isReview ? 'padding-left' : '';
    const optionalCodeSpan = <span className={`optional-code ${!isReview && 'pl-2'}`}>{subItem.ITEMID}</span>;
    const checkBoxShowStatus = !isReview;
    const priceAndCurrencyShow = isReview && includeInbasePrice;
    const handleCheckBoxClick = () => {
        onCheckBoxChange?.();
    };

    if (!show) {
        return <></>;
    }
    return (
        <div className='row mt-2 sub-items'>
            <div className='col-3 d-flex align-items-center'>
                <CheckBox checked={isSelected} show={checkBoxShowStatus} onChange={handleCheckBoxClick} />
                {optionalCodeSpan}
            </div>
            <div className={`col-5 ${divClass} pr-2`}>
                <span>{subItem.DESCRIPTION}</span>
            </div>
            {!priceAndCurrencyShow && (
                <>
                    <div className={`col-2 ${divClass} text-right`}>
                        <span>{subItem.AMOUNT}</span>
                    </div>
                    <div className={`col-2 ${divClass} text-right`}>
                        <span>{subItem.CURRENCY}</span>
                    </div>
                </>
            )}
        </div>
    );
}

function TotalDetails({
    total,
    isSubmitted,
    currency
}: // isIncluded
{
    total: number;
    isSubmitted: boolean;
    currency: string;
    // isIncluded: boolean;
}) {
    if (!isSubmitted) {
        return <></>;
    }
    return (
        <div className={`row ${isSubmitted ? 'mt-3' : ' mt-2'} total-details`}>
            <div>
                <span className='col-3'>Sub Total :</span>
                <span className='main-item-text col-2 '>{total}</span>
                <span className='main-item-text '>{currency}</span>
            </div>
        </div>
    );
}

function ActionButton({
    isSubmitted,
    onClose,
    onBack,
    onSubmit,
    onAddToCart,
    isLoading
}: {
    isSubmitted: boolean;
    onClose: () => void;
    onBack: () => void;
    onSubmit: () => void;
    onAddToCart?: () => void;
    isLoading: boolean;
}) {
    if (isSubmitted) {
        return (
            <div className='sub-item-action-buttons'>
                <button className='modal-cancel-btn' onClick={onBack}>
                    Back
                </button>
                <button className={`modal-add-cart-btn ${!isSubmitted && 'ml-2'}`} onClick={onAddToCart} disabled={isLoading}>
                    ADD TO CART
                </button>
            </div>
        );
    }
    return (
        <div className='sub-item-action-buttons'>
            <button className='modal-cancel-btn' onClick={onClose}>
                Cancel
            </button>
            <button className={`modal-add-cart-btn ${!isSubmitted && 'ml-2'}`} onClick={onSubmit}>
                Save & Continue
            </button>
        </div>
    );
}
const ConfigurableItemError: React.FC<IBusinessSignUpErrorProps> = ({ id, className, type = 'page', message }) => {
    return (
        <div
            id={id}
            className={className}
            role='alert'
            aria-live='assertive'
            aria-label='Address fields are optional. If you choose to submit an address, please complete the rest of the address fields.'
        >
            <p className={`${className}-text`}>{message}</p>
        </div>
    );
};

export interface GetAddSubItemsToCartInputProps {
    selectedSubItems: ConfigurableItems[];
    mainProduct: SimpleProduct;
    configurableItemUUID: string;
    count: number;
    currency?: string;
    catalogId?: number;
    addtoCartComponentProps: IAddToCartComponentProps;
}

const getAddSubItemsToCartInput: (props: GetAddSubItemsToCartInputProps) => Promise<AddConfigurableItemToCartInput> = async props => {
    const { selectedSubItems, mainProduct, configurableItemUUID, count, catalogId, addtoCartComponentProps, currency } = props;
    const addToCartInput: AddConfigurableItemToCartInput = {
        mainProduct: { product: mainProduct, currency },
        subItemProducts: [],
        count,
        catalogId,
        CartLineUUID: configurableItemUUID
    };
    // mainProduct.MasterProductId === undefined
    //     ? []
    //     : [
    //           {
    //               product: mainProduct,
    //               count,
    //               catalogId
    //           }
    //       ];
    const selectedSubItemIds = selectedSubItems.filter(subItem => subItem.isSelected).map(subItem => subItem.SUBPRODUCTLINERECID);
    if (!Boolean(selectedSubItemIds.length)) {
        return addToCartInput;
    }
    const selectedSubItemProducts: SimpleProduct[] = await getByIdsAsync(
        { ...addtoCartComponentProps.context, callerContext: addtoCartComponentProps.context.actionContext },
        addtoCartComponentProps.context.request.apiSettings.channelId,
        selectedSubItemIds
    );

    if (ArrayExtensions.hasElements(selectedSubItemProducts)) {
        selectedSubItemProducts.map(subItemProduct => {
            addToCartInput.subItemProducts.push({
                product: subItemProduct
            });
        });
    }
    return addToCartInput;
};

const getAddToCartInputItems: (props: GetAddSubItemsToCartInputProps) => Promise<AddtoCartInput[]> = async props => {
    const { selectedSubItems, mainProduct, count, catalogId, addtoCartComponentProps } = props;
    const addToCartInput: AddtoCartInput[] =
        mainProduct.MasterProductId === undefined
            ? []
            : [
                  {
                      product: mainProduct,
                      count,
                      catalogId
                  }
              ];
    const selectedSubItemIds = selectedSubItems.filter(subItem => subItem.isSelected).map(subItem => subItem.SUBPRODUCTLINERECID);
    if (!Boolean(selectedSubItemIds.length)) {
        return addToCartInput;
    }
    const selectedSubItemProducts: SimpleProduct[] = await getByIdsAsync(
        { ...addtoCartComponentProps.context, callerContext: addtoCartComponentProps.context.actionContext },
        addtoCartComponentProps.context.request.apiSettings.channelId,
        selectedSubItemIds
    );

    if (ArrayExtensions.hasElements(selectedSubItemProducts)) {
        selectedSubItemProducts.map(subItemProduct => {
            addToCartInput.push({
                product: subItemProduct,
                count,
                catalogId
            });
        });
    }
    return addToCartInput;
};
