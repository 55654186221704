import {
    BaseCartState,
    ICartState,
    ICartActionResult,
    ICartActionResultWithCart,
    addProductsToCartInternal,
    getCartState
} from '@msdyn365-commerce/global-state';
import createGlobalStateDataAction from './global-state-data-actions';
import { Address, CartLine, OrgUnitLocation, ReleasedProductType, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { IActionContext, getCatalogId } from '@msdyn365-commerce/core-internal';
import { action } from 'mobx';
import updateConfigurableCartLineQuantityInternal from './update-configurable-cartlines-quantity';

export interface IExtendedCartState extends ICartState {
    /**
     * Adds the specified products to the current cart. If product is already in cart
     * will update its cart line, otherwise will add a new cart line to the cart.
     *
     * @param product - The product to add to the cart.
     * @param count - How many copies of the product to add.
     * @param location - The org unit location, used for BuyOnlinePickupInStore scenarios
     * (If you want item to simply be shipped, leave this parameter undefined).
     */
    addConfigurableProductToCart(input: {
        mainProduct: { product: SimpleProduct; isPriceKeyedIn?: boolean; customPrice?: number; currency?: string };
        subItemProducts: { product: SimpleProduct; isPriceKeyedIn?: boolean; customPrice?: number }[];
        CartLineUUID: string;
        count?: number;
        location?: OrgUnitLocation;
        additionalProperties?: object;
        availableQuantity?: number;
        enableStockCheck?: boolean;
        isAddEmailDeliveryItemToCart?: boolean;
        deliveryMode?: string;
        catalogId?: number;
    }): Promise<ICartActionResult>;
}

export class ExtendedBaseCartState extends BaseCartState implements IExtendedCartState {
    constructor(actionContext: IActionContext) {
        super(actionContext);
    }
    @action
    public async addConfigurableProductToCart(input: {
        mainProduct: { product: SimpleProduct; isPriceKeyedIn?: boolean; customPrice?: number; currency: string };
        subItemProducts: { product: SimpleProduct; isPriceKeyedIn?: boolean; customPrice?: number }[];
        CartLineUUID: string;
        count?: number;
        location?: OrgUnitLocation;
        additionalProperties?: object;
        availableQuantity?: number;
        enableStockCheck?: boolean;
        isAddEmailDeliveryItemToCart?: boolean;
        deliveryMode?: string;
        catalogId?: number;
        retailMultiplePickupModeEnabled?: boolean;
    }): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            const internalInput: {
                cartLineToAdd: CartLine;
                availableQuantity?: number;
                isStockCheckEnabled?: boolean;
                isAddServiceItemToCart?: boolean;
            }[] = [];

            const configurableMainItemVariantSizeString = input.mainProduct.product.Dimensions?.find(
                dimension => dimension.DimensionTypeValue === 3
            )?.DimensionValue?.Value;
            const currencyCode = input.mainProduct.currency;

            const mainProductCartLine: CartLine = {
                CatalogId: input.catalogId ?? getCatalogId(this.actionContext.requestContext),
                Description: input.mainProduct.product.Description,

                // TODO: Investigate this value and what it represents
                EntryMethodTypeValue: 3,
                ItemId: input.mainProduct.product.ItemId,
                ProductId: input.mainProduct.product.RecordId,
                Quantity: input.count || 1,
                TrackingId: '',
                UnitOfMeasureSymbol: input.mainProduct.product.DefaultUnitOfMeasure,
                IsPriceKeyedIn: input.mainProduct.isPriceKeyedIn,
                IsGiftCardLine: input.mainProduct.product.IsGiftCard,
                Price: input.mainProduct.customPrice ? input.mainProduct.customPrice : input.mainProduct.product.Price,
                ExtensionProperties: [
                    { Key: 'isConfigurableItem', Value: { BooleanValue: true } },
                    { Key: 'isConfigurableItemMain', Value: { BooleanValue: true } },
                    { Key: 'configurableMainItemRecId', Value: { StringValue: `${input.mainProduct.product.RecordId}` } },
                    { Key: 'configurableItemGroupUUID', Value: { StringValue: `${input.CartLineUUID}` } }
                    // { Key: 'optionCode', Value: { StringValue: input.mainProduct.optionCode } }
                ]
            };

            if (input.location) {
                if (!this.actionContext.requestContext.channel) {
                    return { status: 'FAILED' };
                }

                // If curbside pick is not available use the default one
                if (input.deliveryMode !== undefined) {
                    mainProductCartLine.DeliveryMode = input.deliveryMode;
                } else {
                    mainProductCartLine.DeliveryMode = input.retailMultiplePickupModeEnabled
                        ? undefined
                        : this.actionContext.requestContext.channel.PickupDeliveryModeCode;
                }

                mainProductCartLine.FulfillmentStoreId = input.location.OrgUnitNumber;
                mainProductCartLine.WarehouseId = input.location.InventoryLocationId;
                mainProductCartLine.ShippingAddress = this._buildAddressFromOrgUnitLocationInExtendedCartState(input.location);
            }

            if (input.isAddEmailDeliveryItemToCart) {
                mainProductCartLine.DeliveryMode = this.actionContext.requestContext.channel?.EmailDeliveryModeCode;
            }

            // Check if the product is service or not by product type
            const PRODUCTASSERVICE = 2 as ReleasedProductType.Service;
            const isAddServiceItemToCart = input.mainProduct.product.ItemTypeValue === PRODUCTASSERVICE;

            internalInput.push({
                cartLineToAdd: mainProductCartLine,
                availableQuantity: input.availableQuantity,
                isStockCheckEnabled: input.enableStockCheck,
                isAddServiceItemToCart
            });

            for (const inputItem of input.subItemProducts) {
                const subItemProductOptionCode = inputItem.product.Dimensions?.find(dimension => dimension.DimensionTypeValue === 3)
                    ?.DimensionValue?.Value;
                const cartLine: CartLine = {
                    CatalogId: input.catalogId ?? getCatalogId(this.actionContext.requestContext),
                    Description: inputItem.product.Description,

                    // TODO: Investigate this value and what it represents
                    EntryMethodTypeValue: 3,
                    ItemId: inputItem.product.ItemId,
                    ProductId: inputItem.product.RecordId,
                    Quantity: input.count || 1,
                    TrackingId: '',
                    UnitOfMeasureSymbol: inputItem.product.DefaultUnitOfMeasure,
                    IsPriceKeyedIn: inputItem.isPriceKeyedIn,
                    IsGiftCardLine: inputItem.product.IsGiftCard,
                    Price: inputItem.customPrice ? inputItem.customPrice : inputItem.product.Price,
                    ExtensionProperties: [
                        { Key: 'isConfigurableItem', Value: { BooleanValue: true } },
                        { Key: 'isConfigurableItemMain', Value: { BooleanValue: false } },
                        { Key: 'configurableMainItemRecId', Value: { StringValue: `${input.mainProduct.product.RecordId}` } },
                        { Key: 'configurableItemGroupUUID', Value: { StringValue: `${input.CartLineUUID}` } },
                        { Key: 'configurableItemVariantSize', Value: { StringValue: `${configurableMainItemVariantSizeString}` } },
                        { Key: 'currency', Value: { StringValue: `${currencyCode}` } },
                        { Key: 'optionCode', Value: { StringValue: subItemProductOptionCode } }
                    ]
                };

                if (input.location) {
                    if (!this.actionContext.requestContext.channel) {
                        return { status: 'FAILED' };
                    }

                    // If curbside pick is not available use the default one
                    if (input.deliveryMode !== undefined) {
                        cartLine.DeliveryMode = input.deliveryMode;
                    } else {
                        cartLine.DeliveryMode = input.retailMultiplePickupModeEnabled
                            ? undefined
                            : this.actionContext.requestContext.channel.PickupDeliveryModeCode;
                    }

                    cartLine.FulfillmentStoreId = input.location.OrgUnitNumber;
                    cartLine.WarehouseId = input.location.InventoryLocationId;
                    cartLine.ShippingAddress = this._buildAddressFromOrgUnitLocationInExtendedCartState(input.location);
                }

                if (input.isAddEmailDeliveryItemToCart) {
                    cartLine.DeliveryMode = this.actionContext.requestContext.channel?.EmailDeliveryModeCode;
                }

                // Check if the product is service or not by product type
                const PRODUCTASSERVICE = 2 as ReleasedProductType.Service;
                const isAddServiceItemToCart = inputItem.product.ItemTypeValue === PRODUCTASSERVICE;

                internalInput.push({
                    cartLineToAdd: cartLine,
                    availableQuantity: input.availableQuantity,
                    isStockCheckEnabled: input.enableStockCheck,
                    isAddServiceItemToCart
                });
            }

            return this._doCartOperationWithRetryInExtendedCartState(() =>
                addProductsToCartInternal(this.cart, this.actionContext, internalInput)
            );
        });
    }

    /**
     * Updates the quantity of the cart line.
     * @param input - The input.
     * @param input.cartLineId - Cart line id.
     * @param input.newQuantity - New quantity.
     * @param input.additionalProperties - Additional properties.
     * @returns The cart action result.
     */
    @action
    public async updateCartLineQuantity(input: {
        cartLineId: string;
        newQuantity: number;
        additionalProperties?: object;
    }): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetryInExtendedCartState(() =>
                updateConfigurableCartLineQuantityInternal(
                    this.cart,
                    input.cartLineId,
                    input.newQuantity,
                    this.actionContext,

                    // @ts-expect-error
                    input.additionalProperties?.isUsingDefaultOrderSettingsMax
                )
            );
        });
    }

    /**
     * Validates inventory across cart lines.
     * @param callback - Callback function.
     * @returns ICartActionResult.
     */
    @action
    private async _doCartOperationWithRetryInExtendedCartState(
        callback: () => Promise<ICartActionResultWithCart>
    ): Promise<ICartActionResult> {
        let callbackResult = await callback();

        if (callbackResult.status === 'SUCCESS') {
            if (callbackResult.cart) {
                this._cart = callbackResult.cart;
            }
            const baseCartState = await getCartState(this.actionContext);
            baseCartState.refreshCart({});
        } else {
            const refreshCartResult = await this.refreshCart({});

            if (refreshCartResult.status === 'SUCCESS') {
                callbackResult = await callback();

                if (callbackResult.status === 'SUCCESS') {
                    if (callbackResult.cart) {
                        this._cart = callbackResult.cart;
                    }
                }
            }
        }

        if (callbackResult.status === 'SUCCESS' && callbackResult.substatus && callbackResult.substatus === 'PRODUCTADDED') {
            this._isProductAddedToCart = true;
        } else {
            this._isProductAddedToCart = false;
        }

        return {
            status: callbackResult.status,
            substatus: callbackResult.substatus,
            errorDetails: callbackResult.errorDetails,
            validationResults: callbackResult.validationResults
        };
    }

    /**
     * Build address from org unit location.
     * @param location - The location.
     * @returns Address.
     */
    private _buildAddressFromOrgUnitLocationInExtendedCartState(location: OrgUnitLocation): Address {
        return {
            RecordId: location.PostalAddressId,
            Name: location.OrgUnitName,
            FullAddress: location.Address,
            Street: location.Street,
            StreetNumber: location.StreetNumber,
            City: location.City,
            DistrictName: location.DistrictName,
            BuildingCompliment: location.BuildingCompliment,
            Postbox: location.Postbox,
            ThreeLetterISORegionName: location.Country,
            ZipCode: location.Zip,
            County: location.County,
            CountyName: location.CountyName,
            State: location.State,
            StateName: location.StateName
        };
    }
}

export default createGlobalStateDataAction<IExtendedCartState>('CARTSTATE', ExtendedBaseCartState);
