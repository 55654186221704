import {
    CacheType,
    IActionContext,
    IAction,
    IActionInput,
    ICommerceApiSettings,
    ICreateActionContext,
    IObservableAction,
    IRequestContext,
    createObservableDataAction,
    getCatalogId
} from '@msdyn365-commerce/core';
import { IGlobalState, GlobalStateConstructor, createGlobalState } from '@msdyn365-commerce/global-state';

export type ExtendedGlobalStateType = 'EXTENDEDCARTSTATE' | 'CARTSTATE' | 'CHECKOUTSTATE';

export class GlobalStateInput<TOut extends IGlobalState> implements IActionInput {
    public readonly stateConstructor: GlobalStateConstructor<TOut>;

    public readonly key: ExtendedGlobalStateType;

    public readonly catalogId?: number;

    private readonly apiSettings: ICommerceApiSettings;

    public constructor(
        key: ExtendedGlobalStateType,
        stateConstructor: GlobalStateConstructor<TOut>,
        apiSettings: ICommerceApiSettings,
        requestContext?: IRequestContext
    ) {
        this.apiSettings = apiSettings;
        this.stateConstructor = stateConstructor;
        this.key = key;

        if (requestContext) {
            this.catalogId = getCatalogId(requestContext);
        }
    }

    public getCacheKey = () => `Key:${this.key}-ChannelId:${this.apiSettings.channelId}-CatalogId:${this.catalogId ?? 0}`;

    public getCacheObjectType = () => this.key;

    public dataCacheType = (): CacheType => 'instance';
}

export async function getState<TOut extends IGlobalState>(input: GlobalStateInput<TOut>, ctx: IActionContext): Promise<TOut> {
    const globalStateObject = createGlobalState(input.stateConstructor, ctx);

    await globalStateObject.initialize();
    return globalStateObject;
}

export default function createGlobalStateDataAction<TOut extends IGlobalState>(
    key: ExtendedGlobalStateType,
    stateConstructor: GlobalStateConstructor<TOut>
): IObservableAction<TOut> {
    return createObservableDataAction({
        action: <IAction<TOut>>getState,
        input: (args: ICreateActionContext) => {
            return new GlobalStateInput(key, stateConstructor, args.requestContext.apiSettings, args.requestContext);
        }
    });
}
